// eslint-disable-next-line import/no-cycle
import { getInstance } from '../../../services/AuthService';
import store from '../../index';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

export default {
  async auth0Login(payload) {
    await fetch(
      `${BACKEND_URL}/sso/login?sub=${payload.sub}`,
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
      },
    ).then(async (res) => {
      const response = await res.json();
      store.commit('auth/setUserData', response.user_info);
    }).catch(() => {
      const auth = getInstance();
      auth.logout();
    });
  },
};
