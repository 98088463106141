<template>
  <NavBarFullSize v-if="!isMobile" :isAdmin="isAdmin" />
  <NavBarMobile v-else :isAdmin="isAdmin" />
</template>

<script>
import NavBarFullSize from './NavBar-FullSize.vue';
import NavBarMobile from './NavBarMobile.vue';
import detectMobileDevice from '../../helpers/detect-user-agent';

export default {
  name: 'NavBar',
  components: {
    NavBarFullSize,
    NavBarMobile,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  computed: {
    isMobile() {
      return detectMobileDevice();
    },
    isAdmin() {
      return this.$store.state.auth.userInfo.roles.includes('all_clients');
    },
  },
};
</script>
