import ErrorService from '../../../helpers/ErrorHandling';

const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;
const HEADERS = {
  'Content-Type': 'application/json',
};
export default {
  async getMostRecentMonthTrends({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(
      `${BACKEND_URL}/strategize_frontend/trends/most_recent_month_trends`,
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: HEADERS,
        body,
      },
    );
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setMostRecentMonthTrends', data.result);
      await commit('setMostRecentMonthTrendsLoading', false);
    }
  },
  // eslint-disable-next-line
  currentMonthInResult({}, payload) {
    let lastResultMonth = payload.substring(0, 3);
    // eslint-disable-next-line
    this.state.trends.monthNames.forEach((month) => {
      if (lastResultMonth === month.abbreviation) {
        lastResultMonth = month.month;
      }
    });
    let date = new Date();
    date = date.toLocaleString('default', { month: 'long' });
    if (date === lastResultMonth) {
      return true;
    }
    return false;
  },
  async getCPLData({ commit }, payload) {
    const body = JSON.stringify(payload);
    const headers = {
      'X-CSRF-Token': `${sessionStorage.getItem('csrfToken')}`,
      'Content-Type': 'application/json',
    };
    const response = await fetch(
      `${BACKEND_URL}/strategize_frontend/trends/cpl_line_chart`,
      {
        method: 'POST',
        headers,
        credentials: 'include',
        mode: 'cors',
        body,
      },
    );
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setCPLData', data);
    }
  },
  async getCPHData({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(`${BACKEND_URL}/strategize_frontend/trends/cph_line_chart`, {
      method: 'POST',
      credentials: 'include',
      mode: 'cors',
      headers: HEADERS,
      body,
    });
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      await commit('setCPHData', data);
    }
  },
  async getLTHData({ commit }, payload) {
    const body = JSON.stringify(payload);
    const response = await fetch(
      `${BACKEND_URL}/strategize_frontend/trends/lth_line_chart`,
      {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: HEADERS,
        body,
      },
    );
    const data = await response.json();
    if (ErrorService.handleResponse(response, data)) {
      commit('setLTHData', data);
    }
  },
};
