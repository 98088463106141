<template>
  <div>
    <v-app-bar
      color="grey darken-3 text--white"
      fixed
      height="70px"
      class="navbar-fullsize"
    >
      <div class="strategize-logo-spot">
        <div class="strategize-logo" />
      </div>
      <v-list class="topbar-grouping">
        <v-list-item>
          <div class="strategize-logo" />

          <router-link
            class="center"
            v-if="competitionPageSelected === 'Driver'"
            id="market"
            :to="{ name: 'Trends' }"
            @click.native="makeActiveNavItem('Trends')"
          >
            MARKET TRENDS
          </router-link>
          <router-link
            class="location-navlink center"
            v-if="competitionPageSelected === 'Driver'"
            id="locations"
            :to="{ name: 'Locations' }"
            @click.native="makeActiveNavItem('Locations')"
          >
            LOCATIONS
          </router-link>
          <router-link
            class="competition-navlink center"
            :id="`Job Postings ${competitionPageSelected}`"
            :to="{ name: `Job Postings ${competitionPageSelected}` }"
            @click.native="makeActiveNavItem(`Job Postings ${competitionPageSelected}`)"
          >
            COMPETITION
          </router-link>
          <router-link
            v-if="competitionPageSelected === 'Mechanic'"
            class="competition-navlink center"
            id="Hourly Wages"
            :to="{ name: `Hourly Wages` }"
            @click.native="makeActiveNavItem(`Hourly Wages`, true)"
          >
            REPORTED WAGES & EMPLOYMENT
          </router-link>
        </v-list-item>
      </v-list>
      <v-spacer></v-spacer>
      <v-select
        variant="outlined"
        :key="key"
        style="max-width: 200px;"
        class="mt-4 driver-or-mechanic-select"
        density="compact"
        @change="handleDataChange"
        :menu-props="{offsetY: true, 'content-class': 'driver-or-mechanic-select'}"
        v-model="competitionDataType"
        :items="driverOrMechancisSelectItems"
      ></v-select>
      <v-btn icon>
        <v-icon
          size="18px"
          color="#cfcfcf"
          ref="helpButton"
          @click="showModalDialog"
          aria-label="Help Icon"
        >
          mdi-help
        </v-icon>
      </v-btn>

      <v-btn icon>
        <Notifications />
      </v-btn>

      <v-btn aria-label="Account" icon>
        <v-icon
          size="20px"
          color="#cfcfcf"
          class="account-menu-icon"
          id="menu-activator"
        >
          mdi-account
        </v-icon>
        <v-menu
          location="bottom"
          :position-y="75"
          activator="#menu-activator"
          :absolute=true
          close-on-content-click
        >
          <v-list
            color="grey darken-1"
            dark
          >
            <v-list-item v-if="isAdmin">
              <span @click="goToAdminPage">
                <v-icon>admin_panel_settings</v-icon>
                <span>Admin</span>
              </span>
            </v-list-item>
            <v-list-item>
              <span @click="logout">
                <v-icon> mdi-logout </v-icon>
                <span>Logout</span>
              </span>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>

      <v-menu
        :position-y="75"
        :nudge-width="200"
        offset-y
        class="rounded-0"
        absolute
        color="grey darken-2"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              size="20px"
              color="#cfcfcf"
            >
              mdi-apps
            </v-icon>
          </v-btn>
        </template>

        <v-card
          class="waffle-menu"
          :position-y="70"
          :absolute=true
        >
          <WaffleMenu />
        </v-card>
      </v-menu>
      <div class="d-flex align-center justify-center stratas-logo-background ">
        <div class="stratas-logo center" />
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WaffleMenu from './WaffleMenu.vue';
import Notifications from './Notifications-FullSize.vue';
import setDialogPosition from '../../helpers/contact-us-dialog';
import { getInstance } from '../../services/AuthService';

export default {
  name: 'NavBar-FullSize',
  props: {
    isAdmin: { type: Boolean, required: false },
  },
  components: {
    WaffleMenu,
    Notifications,
  },
  data() {
    return {
      competitionDataType: 'Driver Insights',
      previousSelection: 'Driver Insights',
      key: 0,
      driverOrMechancisSelectItems: ['Driver Insights', 'Mechanic Insights'],
    };
  },
  created() {
    this.competitionDataType = `${this.competitionPageSelected} Insights`;
    this.previousSelection = `${this.competitionPageSelected} Insights`;
  },
  computed: {
    ...mapGetters({
      competitionPageSelected: `global/getDriverOrMechanicData`,
      userRolesObject: 'auth/getUserRolesObject',
    }),
  },
  methods: {
    hasAccess(typeOfAccessRequested) {
      const auth = getInstance();
      // eslint-disable-next-line max-len
      const roles = Object.keys(auth.user?.auth0_permissions?.permissions_modules?.strategize) || [];
      const requestedRole = this.userRolesObject[typeOfAccessRequested];
      if (roles.includes(requestedRole)) {
        return true;
      }
      return false;
    },
    handleDataChange(selection) {
      if (this.hasAccess(selection.split(' ')[0].toLowerCase())) {
        this.$router.push(`/${selection.split(' ')[0].toLowerCase()}/competition/job-postings`);
        this.$store.commit('global/setDriverOrMechanicData', selection.split(' ')[0]);
        this.previousSelection = selection;
        sessionStorage.setItem('lastPageViewed', JSON.stringify({ linkId: `Job Postings ${selection.split(' ')[0]}` }));
      } else {
        this.competitionDataType = this.previousSelection;
        this.$store.commit('auth/setRestrictPageAccess', true);
        this.$store.commit('auth/setRestrictedPageAccessType', selection.split(' ')[0]);
      }
      this.key += 1;
    },
    showModalDialog() {
      // Removes focus from help icon
      this.$refs.helpButton.$el.blur();
      // eslint-disable-next-line no-undef
      createHubspotForm();
      setDialogPosition();
    },
    logout() {
      const auth = getInstance();
      auth.logout();
    },
    goToAdminPage() {
      let route = { linkId: 'Admin' };
      route = JSON.stringify(route);
      sessionStorage.setItem('lastPageViewed', route);
      this.$router.push({ name: 'Admin' });
    },
    makeActiveNavItem(data, mechanicPage = false) {
      const determineMechanic = data.includes('Mechanic') || mechanicPage;
      let route = { linkId: data, section: determineMechanic ? 'mechanic' : 'driver' };
      route = JSON.stringify(route);
      sessionStorage.setItem('lastPageViewed', route);
    },
  },
};
</script>

<style lang="scss" scoped>
  .navbar-fullsize {
    display: block !important;
    z-index: 100 !important;
  }
  .waffle-menu {
    img {
      margin: 10px;
    }
  }
  .stratas-logo {
    content: '';
    background-image: url('../../assets/Stratas-Logo-Color.svg');
    background-repeat: no-repeat;
    height: 35px;
    width: 150px;
    margin-left: 20%;
  }
  .stratas-logo-background {
    background-color: #5a5959;
    width: 100%;
    height: 100%;
  }
  .strategize-logo-spot {
    display: contents;
  }
  .strategize-logo {
    content: '';
    background-image: url('../../assets/Strategize-Horizontal-white-TM.svg');
    background-repeat: no-repeat;
    height: 52%;
    margin: 14px -18px 18px 30px;
    width: 10%;
  }
  .stratas-logo {
    content: '';
    background-image: url('../../assets/Stratas-Logo-Color.svg');
    background-repeat: no-repeat;
    height: 35px;
    width: 150px;
    margin-left: 20%;
  }
  .stratas-logo-background {
    background-color: #5a5959;
    height: 100%;
    width: 250px;
  }

  .topbar-grouping {
    background: none !important;
  }
  .v-list-item.theme--light {
    border-bottom: none !important;
    a {
      margin: 10px 20px;
      text-decoration: none;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      color: #e3e3e3!important;
      height: 22px;
      cursor: pointer;
      white-space: nowrap;

      &.is-active {
        border-bottom: 0.1rem solid #70a300;
        opacity: 1;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
</style>
