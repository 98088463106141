import Vue from 'vue';
import { Auth0Client } from '@auth0/auth0-spa-js';
import auth from '../store/modules/auth/actions';
import store from '../store/index';

let instance;

export const getInstance = () => instance;

export const useAuth = ({ redirectUri = window.location.origin, ...options }) => {
  if (instance) return instance;

  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        error: null,
      };
    },
    methods: {
      async loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect({
          ...o,
        });
      },
      async logout(o) {
        this.isAuthenticated = false;
        this.user = {};
        localStorage.clear();
        sessionStorage.clear();
        this.auth0Client.logout({
          ...o,
          logoutParams: {
            returnTo: process.env.VUE_APP_FRONTEND_URL,
          },
        });
      },
      async handleRedirectCallback() {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          if (this.user.auth0_permissions.restrictions.blocked_modules.includes('strategize')) {
            window.location.href = process.env.VUE_APP_PRODUCT_INFO_URL;
            return;
          }
          if (!this.user.auth0_permissions.permissions_modules
            || !this.user.auth0_permissions.permissions_modules.strategize
            || this.user.auth0_permissions.permissions_modules.strategize.length === 0) {
            window.location.href = process.env.VUE_APP_PRODUCT_INFO_URL;
            return;
          }
          await auth.auth0Login({ sub: this.user.sub });
          this.isAuthenticated = true;
        } catch (e) {
          await this.logout();
          this.error = e;
        } finally {
          this.loading = false;
        }
      },
    },
    async created() {
      this.loading = true;
      try {
        this.auth0Client = new Auth0Client({
          domain: options.domain,
          clientId: options.clientId,
          authorizationParams: {
            redirect_uri: redirectUri,
          },
          cacheLocation: 'localstorage',
        });
        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        if (
          window.location.search.includes('code=')
          && window.location.search.includes('state=')
        ) {
          await this.handleRedirectCallback();
        } else {
          this.isAuthenticated = await this.auth0Client.isAuthenticated();
          store.commit('auth/setRoles', localStorage.getItem('roles'));
          store.commit('auth/setChameleonId', localStorage.getItem('chameleonId'));
        }
      } catch (e) {
        await this.logout();
        this.error = e;
      } finally {
        this.loading = false;
      }
    },
  });

  return instance;
};

export const AuthPlugin = {
  // eslint-disable-next-line no-shadow
  install(Vue, options) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$auth = useAuth(options);
  },
};
