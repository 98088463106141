export default {

  setUserData(state, payload) {
    state.userEmail = payload.email_address;
    state.userFullName = payload.full_name;
    state.userSsoId = payload.user_sso_id;
    localStorage.setItem('roles', payload.roles);
    localStorage.setItem('chameleonId', payload.chameleonId);
    state.userInfo.roles = payload.roles;
    state.userInfo.chameleonId = payload.chameleon_token;
  },
  setRoles(state, payload) {
    state.userInfo.roles = payload;
  },
  setChameleonId(state, payload) {
    state.chameleonId = payload;
  },
  setUserRoles(state, payload) {
    state.userInfo.roles = payload.roles;
    state.userEmail = payload.email_address;
    state.userInfo.chameleonId = payload.chameleon_token;
    state.userSsoId = payload.sso_id;
  },
  setRestrictPageAccess(state, payload) {
    state.restrictPageAccess = payload;
  },

  setRestrictedPageAccessType(state, payload) {
    state.restrictedPageAccessType = payload;
  },
};
