/* ============
 * Auth Module
 * ============
 */

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// initial state
const state = () => ({
  userInfo: {
    roles: [],
    chameleonId: null,
  },
  userEmail: '',
  userFullName: '',
  userSsoId: '',
  userRolesObject: {
    driver: 'strategize_driver',
    mechanic: 'strategize_mechanic',
    admin: 'admin',
    all_clients: 'all_clients',
  },
  restrictPageAccess: false,
  restrictedPageAccessType: '',
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
