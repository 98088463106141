import Vue from 'vue';
import { getInstance } from '../services/AuthService';

export default class ErrorService {
  static async handleResponse(response, data) {
    if (response && response.status >= 400 && response.status <= 499) {
      if (response.status === 409) {
        ErrorService.sendWarnMessage(data);
        return false;
      }

      if (response.status === 401) {
        const errorMsg = { message: `Please log back in to continue.` };
        ErrorService.sendErrorMessage(errorMsg);
        setTimeout(async () => {
          getInstance().logout();
        }, 1000);
      }

      return false;
    }

    if (response.status === 500) {
      const newData = data;
      newData.message = 'Something went wrong!';

      ErrorService.sendErrorMessage(newData);
      return false;
    }

    if (response.status === 200 && data.message === undefined) return true;
    return true;
  }

  static displayAlert(message, type) {
    Vue.$toast.open({
      message,
      type,
      duration: 3000,
    });
  }

  static logError(error) {
    this.displayAlert(error.message, 'error');
  }

  static sendErrorMessage(data) {
    this.displayAlert(data.message, 'error');
  }

  static sendWarnMessage(data) {
    this.displayAlert(data.message, 'warning');
  }

  static sendOkMessage(data) {
    this.displayAlert(data.message, data.type);
  }
}
