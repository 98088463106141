/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
/* eslint-disable no-else-return */

import Vue from 'vue';
import Router from 'vue-router';
import * as Sentry from '@sentry/vue';
import Trends from '../components/Market/Trends.vue';
import RecruitingDifficulty from '../components/Locations/Maps/RecruitingDifficulty.vue';
import Locations from '../components/Locations/Locations.vue';
import JobPostings from '../components/Competition/JobPostings.vue';
import Login from '../components/Login.vue';
import Admin from '../components/Pages/Admin.vue';
import NotFound from '../components/Pages/NotFound.vue';
import Domo from '../components/Domo.vue';
import Wages from '../components/Wages/Wages.vue';
import AnnualWages from '../components/Wages/AnnualWages.vue';
import { getInstance } from '../services/AuthService';
import store from '../store/index';

Vue.use(Router);

async function getUserRoles() {
  const auth = getInstance();
  let authorizedClients = [];
  // eslint-disable-next-line max-len
  if (auth.user?.auth0_permissions && Object.keys(auth.user?.auth0_permissions?.permissions_modules) !== 0) {
    if (auth.user?.auth0_permissions?.permissions_modules?.strategize) {
      // eslint-disable-next-line max-len
      authorizedClients = Object.keys(auth.user?.auth0_permissions?.permissions_modules?.strategize);
    }
  }
  if (auth.user?.auth0_permissions?.permissions_global.length > 0) {
    authorizedClients.push('all_clients');
  }
  return authorizedClients;
}

async function defaultRouteHandler(to, from, next) {
  const auth = getInstance();
  if (auth.loading) {
    // Wait for the Auth service to load
    await new Promise((resolve) => {
      const unwatch = auth.$watch('loading', (loading) => {
        if (!loading) {
          unwatch();
          resolve();
        }
      });
    });
  }
  if (auth.isAuthenticated) {
    const lastPageViewed = sessionStorage.getItem('lastPageViewed');
    if (lastPageViewed) {
      const parsedPage = JSON.parse(lastPageViewed);
      return next({ name: parsedPage.linkId });
    }
    const userRoles = await getUserRoles();
    if (userRoles.includes('strategize_driver')) {
      return next({ name: 'Job Postings Driver' });
    } else if (userRoles.includes('strategize_mechanic')) {
      return next({ name: 'Job Postings Mechanic' });
    } else if (userRoles.length === 0) {
      window.location.href = process.env.VUE_APP_PRODUCT_INFO_URL;
    }
  } else {
    await auth.loginWithRedirect();
  }
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
      beforeEnter: defaultRouteHandler,
    },
    {
      path: '/callback',
      beforeEnter: defaultRouteHandler,
    },
    {
      path: '/driver/market/trends',
      name: 'Trends',
      component: Trends,
      meta: {
        requiresAuth: true,
        requiredRole: 'strategize_driver',
      },
    },
    {
      path: '/driver/locations/overview',
      name: 'Locations',
      component: Locations,
      meta: {
        requiresAuth: true,
        requiredRole: 'strategize_driver',
      },
    },
    {
      path: '/driver/locations/map',
      name: 'Maps',
      component: RecruitingDifficulty,
      meta: {
        requiresAuth: true,
        requiredRole: 'strategize_driver',
      },
    },
    {
      path: '/driver/competition/job-postings',
      name: 'Job Postings Driver',
      component: JobPostings,
      meta: {
        requiresAuth: true,
        requiredRole: 'strategize_driver',
      },
    },
    {
      path: '/mechanic/competition/job-postings',
      name: 'Job Postings Mechanic',
      component: Domo,
      meta: {
        requiresAuth: true,
        requiredRole: 'strategize_mechanic',
      },
    },
    {
      path: '/logout',
      name: 'Logout',
      beforeEnter: async (to, from, next) => {
        const auth = getInstance();
        await auth.logout();
      },
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
      meta: {
        requiresAuth: true,
        requiredRole: 'all_clients',
      },
    },
    {
      path: '/mechanic/reported-wages/hourly',
      name: 'Hourly Wages',
      component: Wages,
      meta: {
        requiresAuth: true,
        requiredRole: 'strategize_mechanic',
      },
    },
    {
      path: '/mechanic/reported-wages/annual',
      name: 'Annual Wages',
      component: AnnualWages,
      meta: {
        requiresAuth: true,
        requiredRole: 'strategize_mechanic',
      },
    },
    { path: '*', component: NotFound },
  ],
});

router.beforeEach(async (to, from, next) => {
  const authService = Vue.prototype.$auth;
  if (authService.loading) {
    // Wait until the Auth service is loaded
    await new Promise((resolve) => {
      const unwatch = authService.$watch('loading', (loading) => {
        if (!loading) {
          unwatch();
          resolve();
        }
      });
    });
  }
  const userRoles = await getUserRoles();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!authService.isAuthenticated) {
      return authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
    }
    const { requiredRole } = to.meta.requiredRole;
    if (userRoles.length === 0) {
      window.location.href = process.env.VUE_APP_PRODUCT_INFO_URL;
      return;
    }
    if (requiredRole && !userRoles.includes(requiredRole)) {
      return next({ name: 'Login' });
    }
  }
  const lastPageViewed = sessionStorage.getItem('lastPageViewed');
  if (lastPageViewed) {
    const parsedPage = JSON.parse(lastPageViewed);
    if (parsedPage && userRoles.includes(parsedPage.requiredRole)) {
      return next({ name: parsedPage.routeName });
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (userRoles.includes('strategize_driver')) {
      store.commit('global/setDriverOrMechanicData', 'Driver');
      sessionStorage.setItem('lastPageViewed', JSON.stringify({ linkId: 'Job Postings Driver' }));
    } else if (userRoles.includes('strategize_mechanic')) {
      store.commit('global/setDriverOrMechanicData', 'Mechanic');
      sessionStorage.setItem('lastPageViewed', JSON.stringify({ linkId: 'Job Postings Mechanic' }));
    }
  }
  if (process.env.NODE_ENV === 'production') {
    Sentry.configureScope((scope) => scope.setTransactionName(to?.meta?.label));
  }

  if (window.heap) {
    window.heap.track(to.name, { path: to.path });
  }

  if (to.name === 'Login' || to.name === 'Callback' || to.name === 'Admin') {
    return next();
  }
  next();
});

export default router;
